import React, { useState }  from 'react';
import styled from 'styled-components';
import Spacer from '../../components/Spacer';
import { volvo } from '../../theme/volvo';
//import { Button } from 'react-bootstrap';
import Button from '../../components/Button';

const Flex = styled.div`
    display: block;
    position: absolute;
    bottom: 24px;
    left: calc(50% - 100px);
    
`;

const ModelCard = styled.div`
    position: relative;
    border: 1px solid ${volvo.color.primitive.grey800};
    border-radius: 3px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(189,189,189,1);
    -moz-box-shadow: 2px 2px 5px 0px rgba(189,189,189,1);
    box-shadow: 2px 2px 5px 0px rgba(189,189,189,1);
    background-image: url("${props => props.img}");
    background-repeat: no-repeat;
    background-position: center 30px;
    -o-background-size:70% auto;
    -webkit-background-size:70% auto;
    -moz-background-size:70% auto;
    background-size:70% auto;
    width: 305px;    
    height: 253px;
    margin: 50px 0;
    cursor: pointer;
    &:hover {
        border: 1px solid ${volvo.colors.brand};
    }
    @media (max-width: 1440px) {
        font-size: 20px;
        width: 265px; 
        margin-left: 20px;
        margin-right: 20px;   
    }
`;

const onMove = (e, mouseStatus, swipeDirection, setSwipeDirection) => {
    if (mouseStatus === 1) {
        setSwipeDirection(swipeDirection +  (e.movementX < 0 ? -1  : 1));
    }
}

const onUp = (mouseStatus, swipeDirection, swipeLeft, swipeRight, setSwipeDirection, setMouseStatus) => {
    if (mouseStatus === 1) {
        if (swipeDirection < 0 && Math.abs(swipeDirection) > 5) {
            swipeLeft();
        }
        if (swipeDirection > 0 && Math.abs(swipeDirection) > 5) {
            swipeRight();
        }
    }
    setSwipeDirection(0);
    setMouseStatus(0);
}

const onTouchStart = (e, setTouchDirection) => {
    setTouchDirection(e.touches[0].clientX);
}

const onTouchEnd = (e, touchDirection, swipeLeft, swipeRight) => {
    const currentDirection = e.changedTouches[0].clientX;

    if (touchDirection > currentDirection + 5) {
        swipeLeft();
    } else if (touchDirection < currentDirection - 5) {
        swipeRight();
    }
}

const ModelSelector = ({ src, text, active, onClick, swipeLeft, swipeRight }) => {
    const [swipeDirection, setSwipeDirection] = useState(0);
    const [touchDirection, setTouchDirection] = useState(0);
    const [mouseStatus, setMouseStatus] = useState(0);

    return (
        <ModelCard
            img={src}
            onClick={e => onClick(e)}
            onMouseDown={() => setMouseStatus(1)}
            onMouseUp={() => onUp(mouseStatus, swipeDirection, swipeLeft, swipeRight, setSwipeDirection, setMouseStatus)}
            onMouseMove={e => onMove(e, mouseStatus, swipeDirection, setSwipeDirection)}
            onTouchStart={e => onTouchStart(e, setTouchDirection)}
            onTouchEnd={e => onTouchEnd(e, touchDirection, swipeLeft, swipeRight)}
            selected={active}
        >
            <Flex>
                {active ? (
                    <Button onMouseUp={e => e.stopPropagation()} onClick={e => onClick(e)} selected={active}>{text}</Button>  
                ): (
                    <Button onMouseUp={e => e.stopPropagation()} onClick={e =>  onClick(e)} variant="outline" selected={active}>{text}</Button>  
                )}
                
            </Flex>               
            <Spacer size={4} />
        </ModelCard>
    )
}

ModelSelector.defaultProps = {
    onClick: () => {},
    swipeLeft: () => {},
    swipeRight: () => {},
    src: '',
    text: 'Button',
    active: false,
}

export default ModelSelector;