import React  from 'react';
import styled from 'styled-components';
import { volvo } from '../../theme/volvo'

const ContentBlock = styled.div`
    padding: ${(props) => props.nopadding ? '0': '22px'} 8%;
    background: ${props => props.grayed ? '#fafafc' : volvo.color.background.primary };
`;
const ContentWrapper = styled.div`
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
`;

const Content = ({ children, grayed, nopadding }) => (
    <ContentBlock
        grayed={grayed}
        nopadding={nopadding}
    >
        <ContentWrapper>
            {children}
        </ContentWrapper>
    </ContentBlock>
)

Content.defaultProps = {
    grayed: false,
    nopadding: false,
}

export default Content;