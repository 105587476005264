const volvo = {
    color: {
        primitive: {
            grey200: '#000000',
            grey400: '#707070',
            grey800: '#e9e9e9',
        },
        ornament : {
            highlight: '#1C6BBA',
        },
        background: {
            primary: '#ffffff',
        }
    },
    colors: {
        brand: '#000000',
    }
}

export { volvo }
