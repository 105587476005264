import React from 'react';
import styled from 'styled-components';
import { volvo } from '../../theme/volvo';

const StyledButton = styled.div`
    display: block;
    border: 1px solid ${volvo.color.ornament.highlight};
    background-color: ${(props) => props.selected ? volvo.color.ornament.highlight : 'white' };
    color: ${(props) => props.selected ? 'white' : volvo.color.ornament.highlight};
    text-align: center;
    width: 200px;
    padding: 14px 0;

`;


const Button = ({ children, selected }) => (
    <StyledButton selected={selected}>{children}</StyledButton>
)

export default Button;