import React from 'react';
import { default as ReactSlider } from 'rc-slider';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';
import { volvo } from '../../theme/volvo';

const SlideHolder = styled.div`
    padding: 0 14px 14px 0;
`;

const Slider = ({ onChange, min, max, value }) => {
    return (
        <SlideHolder>
            <ReactSlider 
                min={min}
                max={max}
                onChange={event => onChange(event)}
                trackStyle={{
                    backgroundColor: volvo.color.ornament.highlight,
                    height: 5
                }}
                handleStyle={{
                    borderColor: volvo.color.ornament.divider,
                    height: 24,
                    width: 24,
                    marginLeft: 0,
                    marginTop: -9,
                    backgroundColor: 'white',
                }}
                railStyle={{
                    backgroundColor: volvo.color.ornament.divider,
                    height: 5
                }}
                value={value}
                /> 
            </SlideHolder>
    )
}

Slider.defaultProps = {
    min: 0,
    max: 100,
    value: 0,
    onChange: () => {},
}

export default Slider;
