import axios from 'axios';

export const currentVehicleWeekDay = (store, value) => {
    store.setState({ currentVehicleWeekDay: value });
}

export const currentVehicleWeekendDay = (store, value) => {
    store.setState({ currentVehicleWeekendDay: value });
}

export const currentVehicleFuelEconomy = (store, value) => {
    store.setState({ currentVehicleFuelEconomy: value });
}

export const currentVehicleFuelCost = (store, value) => {
    store.setState({ currentVehicleFuelCost: value });
}

export const volvoVehiclePetrolEconomy = (store, value) => {
    store.setState({ volvoVehiclePetrolEconomy: value });
}

export const volvoVehicleElectricRange = (store, value) => {
    store.setState({ volvoVehicleElectricRange: value });
}

export const volvoVehiclePetrolCost = (store, value) => {
    store.setState({ volvoVehiclePetrolCost: value });
}

export const volvoVehicleElectricityCost = (store, value) => {
    store.setState({ volvoVehicleElectricityCost: value });
}

export const currentVehicleFuelTyle = (store, value) => {
    store.setState({ currentVehicleFuelTyle: value });
}

export const setModelType = (store, value) => {
    store.setState({ modelType: value });
    store.setState({ selectedModel: '-' }); // reset the dropdown
}

export const setSelectedModel = (store, value) => {
    // @todo we might select petrol or diesel default cost depending on the model data
    const newState = {
        selectedModel: value,
        volvoVehiclePetrolCost: store.state.currentUkPetrolCost
    };

    store.setState(newState)
}

export const setWorkCharge = (store, value) => {
    store.setState({ workCharge: value })
}

export const setAverageCo = (store, value) => {
    store.setState({ averageCo: value })
}

export const setAverageCoYear = (store, value) => {
    store.setState({ averageCoYear: value })
}

const setDefaults = (store, response) => {
    let newState = {};
    for(let key in response) {
        if (store.state[key]) {
            newState[key] = response[key];
        }
    }

    if (newState.currentUkPetrolCost) {
        newState['currentVehicleFuelCost'] = newState.currentUkPetrolCost;
    }

    store.setState(newState);
}

export const loadData = (store) => {
    store.setState({ loading: true });

    axios.get(`${process.env.PUBLIC_URL}/data/data.json`)
        .then(function (response) {
            if (response.data && response.data.models) {
                sessionStorage.setItem("volvoCalc", JSON.stringify(response.data));
                store.setState({ modelData: response.data });

                if (response.data.defaults) {
                    setDefaults(store, response.data.defaults);
                }
            }
            store.setState({ loading: false });
        })
        .catch(function (error) {
            const sessionData = sessionStorage.getItem("volvoCalc");

            if (sessionData) {
                store.setState({ modelData: JSON.parse(sessionData) });
            }
            store.setState({ loading: false });
        });
    }