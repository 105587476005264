import React from 'react';
import Main from './app/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

function App() {
  return (
    <Main />
  );
}

export default App;
