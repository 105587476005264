import React from 'react';
import styled from 'styled-components';
import Text from '../../components/Text';
import { volvo } from '../../theme/volvo';

const Container = styled.div`
    display: inline-block;
    width: 100%;
`;

const TitleComponent = styled(Text)`
    color: ${volvo.colors.brand};
    font-family: "VolvoNovum Medium", Arial, sans-serif;
    font-weight: 500;
    font-size: 28px;
    @media (max-width: 1440px) {
        font-size: 20px;
    }
`;

const DigitComponent = styled(Text)`
    color: ${volvo.colors.brand};
    font-family: "VolvoSansPro Medium", Arial, sans-serif;
    font-weight: 500;
    font-size: 72px;
    @media (max-width: 1440px) {
        font-size: 65px;
    }
`;

const RowComponent = styled.div`
    height: 40px;
`;

const LargeYearDisplay = ({ title, digit }) => (
    <Container>
        <RowComponent>
            <TitleComponent>{ title }</TitleComponent>
        </RowComponent>
        <RowComponent>
            <DigitComponent>{ digit }</DigitComponent>
        </RowComponent>
    </Container>    
)

LargeYearDisplay.defaultProps = {
    title: '',
    digit: '',
    large: false,
}

export default LargeYearDisplay;