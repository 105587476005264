import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Content from '../components/Content';
import Summary from '../components/Summary';
import useGlobal from '../store';

const addThousandSeparators = num => {
    if (num) {
        return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 

    return '-';
}; 

const useCalculateSavings = () => {
    const [globalState, globalActions] = useGlobal();
    const [selectedModelId, setSelectedModelId] = useState(-1);
    const subModels = globalState.modelType !== null && globalState.modelData.models.length > 0 && globalState.modelData.models[globalState.modelType] ? globalState.modelData.models[globalState.modelType].subModels : null; 
    const model = globalState.selectedModel !== '-' && subModels ? subModels[globalState.selectedModel] : null;
    
    const result = {
        totalAnnualMilage: '',
        saving1Year: '',
        saving2Years: '',
        saving3Years: '',
        saving1Month: '',
        co2: '',
    }

    useEffect(() => {
        if (model && selectedModelId !==  globalState.selectedModel) {
            setSelectedModelId(globalState.selectedModel);
            globalActions.volvoVehicleElectricRange(model.electricRange);
            globalActions.volvoVehiclePetrolEconomy(model.petrolEconomy);
        }
      }, [globalState, globalActions, selectedModelId, setSelectedModelId, model]);

      if (model) {
        const currentVehicleWeekendTotal = globalState.currentVehicleWeekendDay * 2;

        // calculate current vehice
        const 
            dailyWeekdayElectricRange = globalState.workCharge ? model.electricRange * 2 : model.electricRange,
            weekendElectricMilage = dailyWeekdayElectricRange * 2,
            totalMilesOnElectricWeek = dailyWeekdayElectricRange * 5 + weekendElectricMilage,
            totalAnnualElectricMilage = totalMilesOnElectricWeek * 52;

        const 
            dailyWeekendFuelMilage = globalState.currentVehicleWeekDay - model.electricRange,
            weekendFueLMilage = currentVehicleWeekendTotal - weekendElectricMilage,
            totalMilesOnFuelWeek = dailyWeekendFuelMilage * 5 + weekendFueLMilage,
            totalAnnualFuelMilage = totalMilesOnFuelWeek * 52;

        const totalAnnualMilage = totalAnnualElectricMilage + totalAnnualFuelMilage;
        // calculate electric vehicle
        const
             fuelCostPerGallon = globalState.volvoVehiclePetrolCost * 4.64,
             fuelCostPerYear = (totalAnnualFuelMilage / model.petrolEconomy) * fuelCostPerGallon;

        const
            electricCostPerFullCharge = model.batterySize * globalState.volvoVehicleElectricityCost,
            electricCostPerYear = (electricCostPerFullCharge / model.electricRange) * totalAnnualElectricMilage,
            combinedElectricFuelCostForHybrid = fuelCostPerYear + electricCostPerYear;

        const 
            totalFuelCost = (fuelCostPerGallon / globalState.currentVehicleFuelEconomy) * totalAnnualMilage;

        const 
            saving1Year = Math.round(totalFuelCost - combinedElectricFuelCostForHybrid),
            saving2Years = saving1Year * 2,
            saving3Years = saving1Year * 3,
            saving1Month = Math.round(saving1Year / 12)

        result.totalAnnualMilage  = (globalState.currentVehicleWeekDay * 5 + currentVehicleWeekendTotal) * 52;
        result.saving1Year = saving1Year;
        result.saving2Years = saving2Years;
        result.saving3Years = saving3Years;
        result.saving1Month = saving1Month;
        result.co2 = model.co2;
      }

    return result;
}

const FooterSummary = () => {
   const savings = useCalculateSavings();
   const [globalState] = useGlobal();

   return (
        <Content nopadding>
            <Row style={{display: 'flex', justifyContent: 'center', paddingTop: 25}}>
                <Col>
                    <Summary 
                        years3={addThousandSeparators(savings.saving3Years)}
                        years2={addThousandSeparators(savings.saving2Years)}
                        years1={addThousandSeparators(savings.saving1Year)}
                        month1={addThousandSeparators(savings.saving1Month)}
                        annualMileage={addThousandSeparators(savings.totalAnnualMilage)}
                        coOf={savings.co2}
                        averageCo={globalState.averageCo}
                        averageCoYear={globalState.averageCoYear}
                        />
                </Col>
            </Row>
        </Content>
    )
}

export default FooterSummary;