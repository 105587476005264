import React from 'react';
import Spacer from '../components/Spacer';
import styled from 'styled-components';
import Content from '../components/Content';

const StyledFlex = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 32%;
    padding-right: 32%;
    @media (max-width: 1440px) {
        padding-left: 15%;
        padding-right: 15%;
    }
    @media (max-width: 600px) {
        padding-left: 5%;
        padding-right: 5%;
    }`

const TextHead = styled.div`
    font-size: 40px;
    line-height: 52px;
    
`;

const TextSubHead = styled.div`
    font-size: 16px;
`;

const Head = () => (
    <Content>
        <StyledFlex>
            <Spacer size={4}/>
            <TextHead>Volvo plug-in hybrid calculator</TextHead>
            <Spacer size={2}/>
            <TextSubHead>Below you can see how much of a fuel cost saving you could make on some of our latest plug-in hybrid models. Use the sliders to enter your information.</TextSubHead>
            <Spacer size={6}/>
        </StyledFlex>
    </Content>
)

export default Head;



