import React from 'react';
import globalHook from 'use-global-hook';
import * as actions from '../actions';

const initialState = {
    modelData: {
        models: []
    },
    loading: false,
    selectedModel: '0',
    modelType: 0,
    workCharge: false,
    currentVehicleWeekDay: 20,
    currentVehicleWeekDayMin: 1,
    currentVehicleWeekDayMax: 100,
    currentVehicleWeekendDay: 20,
    currentVehicleWeekendDayMin: 1,
    currentVehicleWeekendDayMax: 100,
    currentVehicleFuelEconomy: 42,
    currentVehicleFuelEconomyMin: 10,
    currentVehicleFuelEconomyMax: 100,
    currentVehicleFuelCost: 1.17,
    currentVehicleFuelCostMin: 1,
    currentVehicleFuelCostMax: 1.38,
    currentVehicleFuelTyle: 'Petrol',
    volvoVehiclePetrolCost: 1.20,
    volvoVehiclePetrolCostMin: 1,
    volvoVehiclePetrolCostMax: 1.38,
    volvoVehicleElectricityCost: 0.14,
    volvoVehicleElectricityCostMin: 0,
    volvoVehicleElectricityCostMax: 0.3,
    averageCoYear: 2018,
    averageCo: 124.5,
    currentUkPetrolCost: 1.20,
    currentUkDieselCost: 1.23,
    volvoVehiclePetrolEconomy: 0, // set from json data
    volvoVehicleElectricRange: 0 // set from json data

};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;
