import React, {  useState } from 'react';
import styled from 'styled-components';
import Spacer from '../../components/Spacer';
import { volvo } from '../../theme/volvo';

const RadioButton = styled.div`
    background: white;
    border: ${(props) => props.checked ? `5px solid ${volvo.color.ornament.highlight}` : `1px solid ${volvo.color.primitive.grey800}` }; 
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const Flex = styled.div`
    display: flex;
    flexDirection: row;
    marginTop: 16px;
    marginBottom: 60px;
`;

const Text = styled.div`
    font-size: 16px;
`;

const LabelText = styled.div`
    font-size: 16px;
    cursor: pointer;
`;

const FuelSelector = ({ isPetrol, onFuelTypeChange }) => {
    const [petrol, setPetrol] = useState(isPetrol);
    return (
        <Flex>
            <Text>My fuel type is:</Text>
            <Spacer />
            <RadioButton
                checked={petrol} 
                onClick={() => {
                    onFuelTypeChange('Petrol');
                    setPetrol(true);
                }}
            />
            <Spacer />
            <LabelText
                onClick={() => {
                    onFuelTypeChange('Petrol');
                    setPetrol(true);
                }}>
                Petrol
            </LabelText>
            <Spacer size={4}/>
            <RadioButton
                checked={!petrol} 
                onClick={() => {
                    onFuelTypeChange('Diesel');
                    setPetrol(false);
                }}
            />
            <Spacer />
            <LabelText
                onClick={() => {
                    onFuelTypeChange('Diesel');
                    setPetrol(false);
                }}>
                Diesel
            </LabelText>
        </Flex>
    )
  
}

FuelSelector.defaultProps = {
    isPetrol: true,
    onFuelTypeChange: () => {},
}

export default FuelSelector;
