import React, {Fragment, useEffect } from 'react';
import Head from './head';
import CurrentVehicle from './currentVehicle';
import VolvoVehicle from './volvoVehicle';
import ModelSelector from './modelSelector';
import WorkCharge from './workCharge'
import FooterSummary from './footerSummary';
import useGlobal from '../store';

function Main() {
    const globalActions = useGlobal()[1];
    useEffect(() => {
        globalActions.loadData()
    }, [globalActions]);
  
  return (
    <Fragment>
        <Head />
        <CurrentVehicle />
        <ModelSelector />
        <VolvoVehicle />
        < WorkCharge />
        <FooterSummary />
    </Fragment>
  );
}

export default Main;
