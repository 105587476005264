import React from 'react';
import styled from 'styled-components';

const SpaceElement = styled.div`
    display: block;
    padding: ${(props) => props.size * 4}px;
`;

const Spacer = ({ size }) => (
    <SpaceElement
        size={size}
     />
     )

Spacer.defaultProps = {
    size: 1
}

export default Spacer;