import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Content from '../components/Content';
import Display from '../components/Display';
import Slider from '../components/Slider';
import { volvo } from '../theme/volvo';
import useGlobal from '../store';

const Hr = styled.div`
    width: 100%
    height: 1px;
    border-top: 1px solid ${volvo.color.primitive.grey200};
`;
const VolvoVehicle = () => {
    const [globalState, globalActions] = useGlobal();
    return (
        <Content>
            <Row>
                <Col lg={6}>
                    <Display
                        inactive={true}
                        title="HYBRID FUEL ECONOMY"
                        digit={globalState.volvoVehiclePetrolEconomy}
                        text="MPG"
                    />
                    <Hr />
                </Col>
                <Col lg={6}>
                    <Display
                        inactive={true}
                        title="HYBRID ELECTRIC RANGE"
                        digit={globalState.volvoVehicleElectricRange}
                        text="miles"
                    />
                    <Hr />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Display
                        isCurrency
                        title="CURRENT PETROL COSTS:"
                        digit={globalState.volvoVehiclePetrolCost.toFixed(2)}
                        text="/l"
                    />
                    <Slider
                    min={globalState.volvoVehiclePetrolCostMin * 100}
                    max={globalState.volvoVehiclePetrolCostMax * 100}
                    value={globalState.volvoVehiclePetrolCost * 100}
                    onChange={value => globalActions.volvoVehiclePetrolCost(value / 100)}
                    />
                </Col>
                <Col lg={6}>
                    <Display
                        isCurrency
                        title="CURRENT ELECTRICITY COSTS:"
                        digit={globalState.volvoVehicleElectricityCost.toFixed(2)}
                        text="/kwh"
                    />
                    <Slider
                        min={globalState.volvoVehicleElectricityCostMin * 100}
                        max={globalState.volvoVehicleElectricityCostMax * 100}
                        value={globalState.volvoVehicleElectricityCost * 100}
                        onChange={value => globalActions.volvoVehicleElectricityCost(value / 100)}
                    />
                </Col>
            </Row>
        </Content>
    );
}

export default VolvoVehicle;