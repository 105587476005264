import React from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import Spacer from '../components/Spacer';
import Content from '../components/Content';
import useGlobal from '../store';

const Flex = styled.div`
    display: flex;
    flexDirection: 'row'
`;
const ToggleLabel = styled.span`
    font-size: 16px;
    vertical-align: top;
    margin-left: 12px;

`;

const WorkCharge = () => {
    const [globalSate, globalSetState] = useGlobal()
        return (
            <Content>
                <Flex>
                    <Spacer />
                    <label>
                        <Toggle
                            icons={false}
                            defaultChecked={globalSate.workCharge}
                            onChange={toggle => globalSetState.setWorkCharge(toggle.currentTarget.value === 'on')}
                        />
                        <ToggleLabel>I have the ability to charge at work</ToggleLabel>
                    </label>
                </Flex>
            </Content>
    )
}

export default WorkCharge;