import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { volvo } from '../../theme/volvo';

const TitleComponent = styled.div`
    font-family: "VolvoSansPro Medium", Arial, sans-serif;
    font-weight: 500;
    color: ${props => props.inactive ? volvo.color.primitive.grey400 : volvo.colors.brand };
    font-size: 16px;
    padding: 6px 0;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const DigitComponent = styled.div`
    font-family: "VolvoSansPro Medium", Arial, sans-serif;
    font-size: 40px;
    font-weight: 500;
    color: ${props => props.inactive ? volvo.color.primitive.grey400 : volvo.colors.brand };
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const TextComponent = styled.div`
    color: ${props => props.inactive ? volvo.color.primitive.grey400 : volvo.colors.brand };
    font-family: "VolvoSansPro Medium", Arial, sans-serif;
    align-self: flex-end;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin-left: 6px;
    padding-top: 4px;
`;

const CurrencySign = styled.div`
    color: ${props => props.inactive ? volvo.color.primitive.grey400 : volvo.colors.brand };
    font-family: "VolvoSansPro Medium", Arial, sans-serif;
    font-size: 1.4em;
    position: relative;
    top: -6px;
    margin-right: 5px;
    font-weight: bold;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const RowComponent = styled.div`
    display: flex;
    line-height: 45px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const Display = ({ title, digit, text, isCurrency, inactive }) => (
    <Fragment>
        <RowComponent>
            <TitleComponent
                subStyle="emphasis"
                variant="ootah"  
                inactive={inactive}
            >
            { title }
            </TitleComponent>
        </RowComponent>
        <RowComponent 
            extend={{
                flexDirection: 'row'
            }}
        >
            {isCurrency && (
                <CurrencySign inactive={inactive}>&pound;</CurrencySign>
            )}
            <DigitComponent
                inactive={inactive}
                variant="cook"  
            >
             { digit }
            </DigitComponent>
            <TextComponent inactive={inactive}>{ text }</TextComponent>
        </RowComponent>
    </Fragment>    
)

Display.propTypes = {
    inactive: PropTypes.bool,
}

Display.defaultProps = {
    title: '',
    digit: '',
    text: '',
    isCurrency: false,
    inactive: false,
}

export default Display;
