import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Spacer from '../components/Spacer';
import styled from 'styled-components';
import Content from '../components/Content';
import Display from '../components/Display';
import Slider from '../components/Slider';
import FuelSelector from '../components/FuelSelector';
import useGlobal from '../store';

const Text = styled.div`
    margin-top: 60px;
    font-size: 28px;
`;

const CurrentVehicle = () => {
    const [globalState, globalActions] = useGlobal();
    return (
        <Content grayed>
                <Row>
                    <Col>
                        <Text>Your current vehicle</Text>
                    </Col>
                </Row>    
                <Row>
                    <Col lg={6} >
                        <Display
                            title="DAILY MILEAGE - WEEKDAY:"
                            digit={globalState.currentVehicleWeekDay}
                            text="miles"
                        />
                        <Slider
                            min={globalState.currentVehicleWeekDayMin}
                            max={globalState.currentVehicleWeekDayMax}
                            value={globalState.currentVehicleWeekDay}
                            onChange={value => globalActions.currentVehicleWeekDay(value)}
                        />
                        <Spacer size={2} />
                    </Col>
                    <Col lg={6} >
                        <Display
                            title="DAILY MILEAGE - WEEKEND:"
                            digit={globalState.currentVehicleWeekendDay}
                            text="miles"
                        />
                        <Slider
                            min={globalState.currentVehicleWeekendDayMin}
                            max={globalState.currentVehicleWeekendDayMax}
                            value={globalState.currentVehicleWeekendDay}
                            onChange={value => globalActions.currentVehicleWeekendDay(value)}
                        />
                        <Spacer size={2} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Display
                            title="MY CURRENT FUEL ECONOMY IS:"
                            digit={globalState.currentVehicleFuelEconomy}
                            text="mpg"
                        />
                        <Slider
                            min={globalState.currentVehicleFuelEconomyMin}
                            max={globalState.currentVehicleFuelEconomyMax}
                            value={globalState.currentVehicleFuelEconomy}
                            onChange={value => globalActions.currentVehicleFuelEconomy(value)}
                        />
                        <Spacer size={4} />
                    </Col>
                    <Col lg={6}>
                        <Display
                            isCurrency
                            title={`MY CURRENT ${globalState.currentVehicleFuelTyle} COSTS ARE:`}
                            digit={globalState.currentVehicleFuelCost.toFixed(2)}
                            text="/L"
                        />
                        <Slider
                            min={globalState.currentVehicleFuelCostMin * 100}
                            max={globalState.currentVehicleFuelCostMax * 100}
                            value={globalState.currentVehicleFuelCost * 100}
                            onChange={value => globalActions.currentVehicleFuelCost(value / 100)}
                        />
                        <Spacer size={4} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <FuelSelector
                            onFuelTypeChange={fuel => {
                                globalActions.currentVehicleFuelTyle(fuel);
                                globalActions.currentVehicleFuelCost(fuel === 'Diesel' ? globalState.currentUkDieselCost : globalState.currentUkPetrolCost);
                            }}
                        />
                    </Col>
                </Row>
        </Content>
    );
}

export default CurrentVehicle;