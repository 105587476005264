import React, { Fragment, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

const CarouselWrapper = styled.div`
    width: 100%;
    height: 355px;
    overflow: hidden;
    position: relative;
`;

const Item = styled.div`
    margin-left: 4px;
    @media (max-width: 1440px) {
        margin-right: 300px;
        margin-left: 0px;
    }
`;

const CarouselFlex = styled.div`
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    justify-content: space-between;
    min-width: 1185px;
    flex-direction: row;
    transition: left 1s;
    @media (max-width: 1440px) {
        left: ${props => props.offset}px;
        justify-content: flex-start;
    }
`;

const CarouselDots = styled.div`
    display: none;
    width: 100%;
    text-align: center;
    @media (max-width: 1440px) {
        display: block;
    }
`;

const CarouselDot = styled.div`
    display: inline-block;
    cursor: pointer;
    border-bottom: 3px solid ${props => props.selected ? '#999999': '#333333'};
    width: 60px;
    height: 15px;
    margin: 12px 6px 36px 6px;
`;

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

const Carousel =  ({ children, id, onChange }) => {
    useWindowSize();
    const [carouselPos, setCarouselPos] = useState(0);
    const [containerWith, setContainerWidth] = useState(0);
    const cPos = (containerWith / 2 - 150) - (id * 605);

    if (cPos !== carouselPos) {
        setCarouselPos(cPos)
    }

    return (
        <Fragment>
            <CarouselWrapper ref={el => setContainerWidth(el ? el.offsetWidth : 50)}>
                <CarouselFlex offset={carouselPos}>
                    {children.map((child, key) => (
                        <Item key={key}>
                            {child}
                        </Item>
                    ))}
                </CarouselFlex>
            </CarouselWrapper>
            <CarouselDots ref={el => setContainerWidth(el ? el.offsetWidth : 50)}>
            {children.map((child, key) => (
                <CarouselDot
                    key={key}
                    selected={id === key}
                    onClick={e => onChange(key) }
                />
            ))}
            </CarouselDots>
        </Fragment>
    )
}

Carousel.defaultProps = {
    onChange: () => {},
    id: 0,
    children: []
}

export default Carousel;
