import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import Spacer from '../../components/Spacer';
import YearDisplay from './yearDisplay';
import LargeYearDisplay from './largeYearDisplay';

const StyledCard = styled.div`
    padding: 0 24px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(189,189,189,1);
    -moz-box-shadow: 2px 2px 5px 0px rgba(189,189,189,1);
    box-shadow: 2px 2px 5px 0px rgba(189,189,189,1);
`;


const Flex = styled.div`
    display: flex;
`
const TextCo = styled.div`
    font-size: 16px;
    line-height: 26px;

`;

const TextMilage = styled.div`
    display: inline;
    font-family: "VolvoSansPro Medium", Arial, sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-top: -3px;
    margin-left: 6px;
`;

const TextAco = styled.div`
    font-size: 14px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const TextHead = styled.div`
    font-size: 28px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;
const TextSubHead = styled.div`
    font-size: 16px;
`;


const Summary = ({ annualMileage, coOf, averageCo, averageCoYear, years3, years2, years1, month1 }) => (
    <Fragment>
        <StyledCard>
            <Card.Body>
                <Row>
                    <Col lg={12}>
                        <Spacer size={4}/>
                        <TextHead>Your Volvo plug-in hybrid savings</TextHead>
                        <Spacer size={2}/>
                        <Flex extend={{flexDirection: 'row'}}>
                            <TextSubHead>These savings are based on your annual mileage of<TextMilage>{annualMileage}</TextMilage></TextSubHead>
                        </Flex>
                    </Col>
                </Row>
                <Spacer size={4} />
                <Row>
                    <Col lg={4}>
                        <LargeYearDisplay 
                            title="3 Years"
                            digit={`£${years3}`}
                        />
                    </Col>
                    <Col lg={3}>
                        <YearDisplay 
                            title="2 Years"
                            digit={`£${years2}`}
                        />
                    </Col>
                    <Col lg={3}>
                        <YearDisplay 
                            title="1 Year"
                            digit={`£${years1}`}
                        />
                    </Col>
                    <Col lg={2}>
                        <YearDisplay 
                            title="1 Month"
                            digit={`£${month1}`}
                        />
                    </Col>
                </Row>
                <Spacer size={4} />
                <Row>
                        <Col lg={12}>
                            <Flex extend={{flexDirection: 'row'}}>
                                <TextCo>Your chosen plug-in hybrid has CO2 emissions of<TextMilage>{coOf}g/km</TextMilage></TextCo>
                            </Flex>                            
                        </Col>
                </Row>
                <Row>
                        <Col lg={12}>
                            <TextAco>
                                UK, average CO2 emissions in {averageCoYear} were {averageCo}g/km
                            </TextAco>
                            <Spacer size={4}/>
                        </Col>
                </Row>
            </Card.Body>
        </StyledCard>
        <Spacer size={8}/>
    </Fragment>
)

Summary.defaultProps = {
    coOf: '',
    averageCoYear: '',
    averageCo: '',
    annualMileage: 0,
    years3: 0,
    years2: 0,
    years1: 0,
    month1: 0
}

export default Summary;
