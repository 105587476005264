import React from 'react';
import styled from 'styled-components';
import Text from '../../components/Text';
import { volvo } from '../../theme/volvo';

const Container = styled.div`
    display: inline-block;
    width: 100%
`;

const TitleComponent = styled(Text)`
    color: ${volvo.colors.brand};
    font-family: "VolvoNovum Medium", Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;

`;
const DigitComponent = styled(Text)`
    color: ${volvo.colors.brand};
    font-family: "VolvoSansPro Medium", Arial, sans-serif;
    font-size: 40px;
    font-weight: 500;
`;

const Box = styled.div`
    border-left: 1px solid ${volvo.color.primitive.grey200};
    padding-left: 45px;
    margin-top: 45px;
    height: 90px;
    @media (max-width: 768px) {
        width: 50%;
        padding-left: 0;
        padding-top: 25px;
        margin-top: 25px;
        border-left: none;
        border-top: 1px solid ${volvo.color.primitive.grey200};
      }
`;

const RowComponent = styled.div`
    height: 24px;
`;

const YearDisplay = ({ title, digit }) => (
    <Container>
        <Box>
            <RowComponent>
                <TitleComponent>{ title }</TitleComponent>
            </RowComponent>
            <RowComponent>
                <DigitComponent>{ digit }</DigitComponent>
            </RowComponent>
        </Box>
    </Container>    
)

YearDisplay.defaultProps = {
    title: '',
    digit: '',
    large: false,
}

export default YearDisplay;
