import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import Spacer from '../components/Spacer';
import Content from '../components/Content';
import Model from '../components/Model';
import Carousel from '../components/Carousel';
import { volvo } from '../theme/volvo';
import useGlobal from '../store';

const SelectInput = styled.select`
    width: 100%;
    padding: 12px 6px;
    height: 53px;
    border: 1px solid ${volvo.color.primitive.grey800};
    border-radius: 2px;
    -webkit-appearance: none;
    background-color: white;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: center right 10px;
`;

const StyledContent = styled(Content)`
    marginTop: 2px;
    marginBottom: 2px;
`;

// @todo reimplement with sone css3 forever looping animation
const LoadingBar = styled.div`
    display: block;
    widht: 100%
    height: 6px;
    background-color: blue;
`;
const Text = styled.div`
    margin-bottom: 24px;
    font-size: 28px;
`;

const ModelSelector = () => {
    let subModels;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [globalState, globalActions] = useGlobal();
    const models = globalState.modelData.models;
    
    if (models && globalState.modelType !== null && models.length > 0) {
        subModels = models[globalState.modelType].subModels.map((item, key) => (
            <option key={key} value={key}>
                {item.name}
            </option>
        ))
    }

    return (
        <StyledContent>
            <Row>
                <Col>
                    <Spacer size={3} />
                    <Text>Select your Volvo Plug-in hybrid model</Text>
                    </Col>
            </Row>
            {globalState.loading && (
                <Row>
                    <Col lg={12}>
                        <LoadingBar
                            isLoading
                            transitionDuration={1500}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col lg={12}>
                    {globalState.loading && (
                        <LoadingBar
                        isLoading
                        transitionDuration={1500}
                    />
                    )}
                    <Carousel 
                        id={currentSlide}
                        onChange={id => setCurrentSlide(id)}
                        >
                        {models.map((model, key) => (
                            <Model 
                                active={key === globalState.modelType}
                                key={key}
                                swipeRight={() => {
                                    if (currentSlide > 0) {
                                        setCurrentSlide(currentSlide - 1)
                                    }
                                }}
                                swipeLeft={() => {
                                    if (currentSlide < models.length - 1) {
                                        setCurrentSlide(currentSlide + 1)
                                    }
                                }}
                                src={model.imageUrl}
                                text={`Select ${model.name}`}
                                onClick={() => {
                                    globalActions.setModelType(key);
                                    globalActions.setSelectedModel('0');
                                }}
                            />
                        ))}
                    </Carousel>
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <SelectInput
                        aria-label=""
                        onChange={e => globalActions.setSelectedModel(e.currentTarget.value)}
                        value={globalState.selectedModel}
                    >
                        <React.Fragment key=".0">
                            <option
                            value="-"
                            >
                            Select a plug-in hybrid model
                            </option>
                            {subModels}
                        </React.Fragment>
                    </SelectInput>
                </Col>
            </Row>
        </StyledContent>
    )
}

export default ModelSelector;
